import React from "react";

import Tabs from "../tab/tabs";
import Panel from "../tab/panel";

class Tb extends React.Component {
  render() {
    return (
      <Tabs selected={1}>
        <Panel index="1" title="TELCO">
          <div className="row">
            <div className="col-md-5">
              <h1>Telecom Networks are part of our core DNA</h1>
            </div>
          </div>
          <div className="row">
            <div className="col-md-5">
              <p> We have deep end-to-end MNO and MVNO expertise</p>
              <p>
                {" "}
                We design networks from 2G to 5G (End2End) including
                transmission{" "}
              </p>
              <p>
                {" "}
                We design last generation 5G and NBIOT ready core networks
                (End2End)
              </p>
            </div>
            <div className="col-md-7">
              <p className="paragraphe_italic">
                5G will create a field of new opportunities leading to the
                breakthrough of EDGE computing in combination with AI{" "}
              </p>
            </div>
          </div>
          <div className="row">
            <div className="col-md-5">
              <p className="paragraphefinal"> Indoor Coverage </p>
              <p className="paragraphefinal">
                {" "}
                We design last generation NB-IOT and LoRa networks (End2End) and
                solutions{" "}
              </p>
              <p className="paragraphefinal">
                {" "}
                NG Fibre optical networks - eOTN{" "}
              </p>
              <p className="paragraphefinal">
                {" "}
                BSS and OSS Digital Transformation{" "}
              </p>
              <p className="paragraphefinal">
                {" "}
                Supply, Support and Managed Services Consulting{" "}
              </p>
            </div>
          </div>
        </Panel>
        <Panel index="2" title="IOT">
          <div className="row">
            <div className="col-md-12">
              <h1>Telecom Networks are part of our core DNA</h1>
            </div>
          </div>

          <div className="row">
            <div className="col-md-12">
              <p>
                {" "}
                Solid foundation - A library of +200 use cases in 10 verticals
                allowing our Multidisciplinary teams to rapidly formulate ideas
                or digital cus- tomer experience use cases and implement them in
                “X-Cite Brain” with low TTM and TCO.{" "}
              </p>
            </div>
          </div>

          <div className="row">
            <div className="col-md-12">
              <div className="elements borderelemnt">
                <div className="element">
                  <span> Automotive </span>
                </div>
                <div className="element">
                  <span> Public Transport </span>
                </div>
                <div className="element">
                  <span> Energy & Utilities </span>
                </div>
                <div className="element">
                  <span> Public Safety & Environment </span>
                </div>
                <div className="element">
                  <span> Agriculture </span>
                </div>
                <div className="smartcity_container">
                  <span className="smartcity">Smart City</span>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <div className="elements">
                <div className="element">
                  <span> Healthcare </span>
                </div>
                <div className="element">
                  <span> Media & Entertainment </span>
                </div>
                <div className="element">
                  <span> Rectangle </span>
                </div>
                <div className="element">
                  <span> Manufacturing </span>
                </div>
                <div className="element">
                  <span> Financial Services </span>
                </div>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-md-12">
              <div className="graphique" />
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <h3> Application based cluster methodology </h3>
            </div>
          </div>

          <div className="row">
            <div className="col-md-12">
              <div className="ellipse_list">
                <div className="ellipse_element">
                  <div className="ellipse_container">
                    <div className="ellipse"> </div>
                  </div>
                  <span className="word"> Enhanced Video Services </span>
                </div>
                <div className="ellipse_element">
                  <div className="ellipse_container">
                    <div className="ellipse"> </div>
                  </div>
                  <span className="word"> Enhanced Video Services </span>
                </div>
                <div className="ellipse_element">
                  <div className="ellipse_container">
                    <div className="ellipse"> </div>
                  </div>
                  <span className="word"> Enhanced Video Services </span>
                </div>
                <div className="ellipse_element">
                  <div className="ellipse_container">
                    <div className="ellipse"> </div>
                  </div>
                  <span className="word"> Enhanced Video Services </span>
                </div>
                <div className="ellipse_element">
                  <div className="ellipse_container">
                    <div className="ellipse"> </div>
                  </div>
                  <span className="word"> Enhanced Video Services </span>
                </div>
              </div>

              <div className="ellipse_list">
                <div className="ellipse_element">
                  <div className="ellipse_container">
                    <div className="ellipse"> </div>
                  </div>
                  <span className="word"> Enhanced Video Services </span>
                </div>
                <div className="ellipse_element">
                  <div className="ellipse_container">
                    <div className="ellipse"> </div>
                  </div>
                  <span className="word"> Enhanced Video Services </span>
                </div>
                <div className="ellipse_element">
                  <div className="ellipse_container">
                    <div className="ellipse"> </div>
                  </div>
                  <span className="word"> Enhanced Video Services </span>
                </div>
                <div className="ellipse_element">
                  <div className="ellipse_container">
                    <div className="ellipse"> </div>
                  </div>
                  <span className="word"> Enhanced Video Services </span>
                </div>
              </div>
            </div>
          </div>
        </Panel>
        <Panel index="3" title="Applied R&D">
          <div className="row">
            <div className="col-md-5">
              <h1>
                Projects with the complete solution from X-CITE combining NB-IOT
                and AI
              </h1>
            </div>
          </div>
          <div className="row">
            <div className="col-md-6">
              <div className="left-side">
                <h3>Edge Machine Learning & AI</h3>
                <ul className="title_list">
                  <li> Supervised Learning </li>
                  <li className="no-bullet">
                    <ul className="element_list">
                      <li>
                        {" "}
                        Edge Data Inferencing (i.e. in the context of Object
                        Detection){" "}
                      </li>
                      <li> Edge Data Learning/Training</li>
                    </ul>
                  </li>
                  <li> Unsupervised Learning </li>
                  <li className="no-bullet">
                    {" "}
                    <ul className="element_list">
                      <li> See https://gym.openai.com/envs/#robotics </li>
                    </ul>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-md-6">
              <div className="left-side">
                <h3>Machine Learning for Streaming Data</h3>
                <ul className="title_list">
                  <li> Enrich Streaming Input Data from Sensors (in real time, no extract & load!) </li>
                  <li> Take actions based on acquired Data from Sensors on the Edge (see local inferencing before) </li>
                </ul>
              </div>
            </div>

             <div className="col-md-6">
              <div className="left-side">
                <h3>Edge Machine Learning & AI</h3>
                <ul className="title_list">
                  <li> Supervised Learning </li>
                  <li className="no-bullet">
                    <ul className="element_list">
                      <li>
                        {" "}
                        Edge Data Inferencing (i.e. in the context of Object
                        Detection){" "}
                      </li>
                      <li> Edge Data Learning/Training</li>
                    </ul>
                  </li>
                  <li> Unsupervised Learning </li>
                  <li className="no-bullet">
                    {" "}
                    <ul className="element_list">
                      <li> See https://gym.openai.com/envs/#robotics </li>
                    </ul>
                  </li>
                </ul>
              </div>
            </div>

             <div className="col-md-6">
              <div className="left-side">
                <h3>Edge Machine Learning & AI</h3>
                <ul className="title_list">
                  <li> Supervised Learning </li>
                  <li className="no-bullet">
                    <ul className="element_list">
                      <li>
                        {" "}
                        Edge Data Inferencing (i.e. in the context of Object
                        Detection){" "}
                      </li>
                      <li> Edge Data Learning/Training</li>
                    </ul>
                  </li>
                  <li> Unsupervised Learning </li>
                  <li className="no-bullet">
                    {" "}
                    <ul className="element_list">
                      <li> See https://gym.openai.com/envs/#robotics </li>
                    </ul>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </Panel>
      </Tabs>
    );
  }
}

export default Tb;
