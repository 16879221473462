import React from "react";
import chat from "../assets/images/icn_chat.png";
import job from "../assets/images/icn_job.png";
import Alltabs from "../components/tabsdomains/alltabs";

const Banner = props => (
  <section id="banner_our_domains" className="major">
    <div className="inner">
      <div className="blocs_banner_our_domains" id="blocs_banner_our_domains">
        <header className="major">
          <h1 className="ourdomains_firsttitle">OUr Domains</h1>
          <hr />
        </header>

        <div id="Tabs_bloc_domains">
          <Alltabs />
        </div>
      </div>

      <div id="blocs_banner_getin_touch" className="blocs_banner_getin_touch">
        <header className="major">
          <h1 className="ourdomains_firsttitle">Get in touch</h1>
          <hr />
        </header>
        <div className="row">
          <div className="col-md-6">
            <div className="left-side">
              <div className="text-center">
                <img src={chat} alt="" />
              </div>
              <div className="text-center">
                <h5> Talk to us </h5>
                <p>
                  {" "}
                  We always love to chat and get to know interesting people.
                  Don’t hesitate to say hello. We promise, we will get back to
                  you :){" "}
                </p>
                <a href="mailto:getintouch@x-cite.io"> Email </a>
              </div>
            </div>
          </div>
          <div className="col-md-6">
            <div className="right-side">
              <div className="text-center">
                <img src={job} alt="" />
              </div>
              <div className="text-center">
                <h5> Join us </h5>
                <p>
                  {" "}
                  Our Global team is growing fast and we are always looking for
                  awesome people to join our teams of engineers.{" "}
                </p>
                <a href="mailto:jobs@x-cite.io"> Apply </a>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div id="blocs_banner_imprint" className="blocs_banner_imprint">
        <header className="major">
          <h1 className="ourdomains_firsttitle">imprint</h1>
          <hr />
        </header>
        <div className="row">
        <div className="col-md-12 imprint_content">
          <p> X-Cite S.A. </p>
          <p>9 Rue du Laboratoire </p>
          <p>L-1911 Luxembourg </p>

          <p>
            e-Mail:{" "}
            <a href="mailto:getintouch@x-cite.io"> getintouch@x-cite.io </a>{" "}
          </p>

          <p>VAT Number: LU30332513 </p>
          <p>Company Registry: B225206 </p>
        </div>
        </div>
      </div>
    </div>
  </section>
);

export default Banner;
