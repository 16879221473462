import React from "react"
import Layout from "./../components/layout"

export default () => (
  <Layout>
   
    {/* <h1>Amazing Pandas Eating Things</h1>
    <div>
      <img
        src="https://2.bp.blogspot.com/-BMP2l6Hwvp4/TiAxeGx4CTI/AAAAAAAAD_M/XlC_mY3SoEw/s1600/panda-group-eating-bamboo.jpg"
        alt="Group of pandas eating bamboo"
      />
    </div> */}

        {/* <div className="container"> 
            <div className="row"> 
            <div className="col-2"> aaaaa</div>
            <div className="col-2">bbbbbbb</div>
       
             </div>
              </div> */}
  </Layout>
)