import React from "react";

import "../assets/scss/main.scss";
import Header from "./Header";
import Menu from "./Menu";
// import Contact from './Contact'
import Footer from './Footer'
import Banner from "./Banner";
import Bannermission from "./Bannermission";
import Bannerwhatwedo from "./bannerwhatwedo";
import Bannerendtoend from "./Bannerendtoend";
import Bannerourdomains from "./Bannerourdomains";

class Layout extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isMenuVisible: false,
      loading: "is-loading"
    };
    this.handleToggleMenu = this.handleToggleMenu.bind(this);
  }

  componentDidMount() {
    this.timeoutId = setTimeout(() => {
      this.setState({ loading: "" });
    }, 100);
  }

  componentWillUnmount() {
    if (this.timeoutId) {
      clearTimeout(this.timeoutId);
    }
  }

  handleToggleMenu() {
    this.setState({
      isMenuVisible: !this.state.isMenuVisible
    });
  }

  render() {
    const { children } = this.props;

    return (
      <div
        className={`body ${this.state.loading} ${
          this.state.isMenuVisible ? "is-menu-visible" : ""
        }`}
      >
        <div id="wrapper">
          <div className="div1">
            <Header onToggleMenu={this.handleToggleMenu} />

            <div className="backgroundheader">
              <Banner />
            </div>
          </div>

          <div className="div2">
            <Bannermission />
            <div className="background22">
              <Bannerwhatwedo />
            </div>
          </div>
          <div className="div3">
          <div className="background33">
          <Bannerendtoend />
            </div>
            
           
          </div>
          <div className="div4" >
          <Bannerourdomains />
          <Footer />

        


          </div>
          {children}
          {/* <Contact /> */}
         
        </div>
        <Menu onToggleMenu={this.handleToggleMenu} />
      </div>
    );
  }
}

export default Layout;
