import React from "react";
import imagedtp from "../assets/images/image.png";
const Banner = props => (
  <section id="banner_what_we_do" className="major">
    <div className="inner">
      <header className="major">
        <h1>What we do</h1>
        <hr />
      </header>
      <div className="content">
        <p>At the forefront of Digital Transformation</p>
        {/* <ul className="actions">
                    <li><a href="#one" className="button next scrolly">Get Started</a></li>
                </ul> */}
      </div>
      <div className="row">
        <div className="col-md-6">
          <h2> Business friendly, integration friendly, migration friendly </h2>
          <p>
            {" "}
            Transform customers into Native Digital Players supporting on-
            demand business models, and intelligent orchestration of network and
            IT functions to enable open and agile service delivery.{" "}
          </p>


          
        </div>
      </div>
      <div className="blocs_banner_wwd">
      
        <div className="Vertical_text">
    
          {/* {" "}
          E2E Digitalzation Design and Delivery Company{" "} */}
       <p className="subtitle fancy"><span> E2E Digitalzation Design and Delivery Company</span></p>
      
        </div>
        {/* <div className="row">
          <div className="col-md-4">
            <h3> Unleashing Digital Power</h3>
            <ul>
              <li>Full Business and Operational Enablement Solutions</li>
              <li>
                Deep knowledge on industry business and operation processes
              </li>
              <li>Cloud native architectures</li>
            </ul>
          </div>

          <div className="col-md-4 text-center">
            <img src={imagedtp} alt="" />
          </div>

          <div className="col-md-4">
            <h3> Make It Easy Doing Business Anywhere</h3>
            <ul>
              <li> Full Web-Scale technologies</li>
              <li>Massive scalability</li>
              <li>Global footprint and ambition</li>
              <li>Rich ecosystem </li>
              <li>IoT and 5G minded</li>
            </ul>
          </div>
        </div> */}

        {/* <div className="row">
          <div className="col-md-3">
            <div className="tree_block">
              <p> Digital Transformation Fast Track </p>
            </div>
          </div>
          <div className="col-md-3">
            <div className="tree_block">
              <p>ET Brain for Improved Experience with AI </p>
            </div>
          </div>
          <div className="col-md-3">
            <div className="tree_block">
              <p> IoT E2E Digital Business Enabler </p>
            </div>
          </div>
          <div className="col-md-3">
            <div className="tree_block">
              <p> Agile Cloud PaaS Delivery</p>
            </div>
          </div>
        </div> */}

 <div id="container">

<ol class="organizational-chart">
    <li>
        <div>
        <h3> Unleashing Digital Power</h3>
            <ul>
              <li>Full Business and Operational Enablement Solutions</li>
              <li>
                Deep knowledge on industry business and operation processes
              </li>
              <li>Cloud native architectures</li>
            </ul>
        </div>

         {/* <div className="">
        <h3> Make It Easy Doing Business Anywhere</h3>
            <ul>
              <li> Full Web-Scale technologies</li>
              <li>Massive scalability</li>
              <li>Global footprint and ambition</li>
              <li>Rich ecosystem </li>
              <li>IoT and 5G minded</li>
            </ul>
        </div> */}

        <div>
           
            <img src={imagedtp} alt="" />
        </div>


        <div className="">
        <h3> Make It Easy Doing Business Anywhere</h3>
            <ul>
              <li> Full Web-Scale technologies</li>
              <li>Massive scalability</li>
              <li>Global footprint and ambition</li>
              <li>Rich ecosystem </li>
              <li>IoT and 5G minded</li>
            </ul>
        </div>

        <ol>
            <li>
                <div>
                    <p>Digital Transformation Fast Track</p>
                </div>
             
            </li>
            <li>
                <div>
                <p>ET Brain for Improved Experience with AI</p>
                </div>
         
            </li>
            <li>
                <div>
                <p>IoT E2E Digital Business Enabler</p>
                </div>
    
            </li>
            <li>
                <div>
                <p>Agile Cloud PaaS Delivery</p>
                </div>
      
            </li>
        </ol>
    </li>
</ol>

</div>
        
     

      </div>

    </div>
  </section>
);

export default Banner;
