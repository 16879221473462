import React from "react";

import Tab from "../tabsdomains/tab";
import Panel from "../tabsdomains/panel";

import grp_5g from "../../assets/images/Group 5g.png";
import edge from "../../assets/images/icn_edge.png";
import cloud from "../../assets/images/Vector cloud.png";

import Ai from "../../assets/images/Vector Ai.png";
import iot from "../../assets/images/icn_iot.png";
import security from "../../assets/images/icn_security.png";

import close_img from "../../assets/images/btn-close.png";

class Alltabs extends React.Component {
  constructor(props) {
    super(props);
    this.childRef = null;
  }

 
  render() {
    return (
      <div>
        <Tab selected={1} name="children" ref="children" onChange={this.onChange.bind(this)}>
          <Panel
            index="1"
            title="5G Networks"
            image={grp_5g}
            description="We are actively assisting you to capture in an early stage 5G business opportunities."
          >
            <div className="close_image">
              <img
                src={close_img}
                alt=""
                onClick={() => this.refs.children.handleChange()}
              />
            </div>
            <div className="row">
              <div className="col-md-12">
                <h1 className="title_popup">
                  Telecom Networks are part of our core DNA
                </h1>
              </div>
            </div>

            <div className="popup_container">
              <div className="row">
                <div className="col-md-12">
                  <ul>
                    <li> NFV & Containerization</li>
                    <li>5G </li>
                    <li> 4.5G </li>
                    <li>Sliced Core and IMS </li>
                    <li>Broadband (BNG) & Backhaul </li>
                    <li>Managed Enterprise Networks </li>
                    <li>(Software Defined) Network: SDN </li>
                    <li>Private DC </li>
                    <li>WAN </li>
                    <li>Network Security </li>
                  </ul>
                </div>
              </div>
            </div>
          </Panel>
          <Panel
            index="2"
            title="Cloud"
            image={cloud}
            description="We design, develop, integrate and operate your Cloud infrastructure."
          >
            <div className="close_image">
              <img
                src={close_img}
                alt=""
                onClick={() => this.refs.children.handleChange()}
              />
            </div>
            <div className="row">
              <div className="col-md-12">
                <h1 className="title_popup">PRODUCT OFFERING OVERVIEW</h1>
              </div>
            </div>
            <div className="popup_container">
              <div className="row">
                <div className="col-md-12">
                  <h2 className="second_title_popup">
                    Evolving from IOT, AI, cognitive, SDN to EDGE (5G)
                  </h2>
                </div>
                <div className="col-md-7">
                  <div class="table-container table-responsive">
                    <table>
                      <tbody>
                        <tr>
                          <td colSpan={14}>Consulting (Technology Strategy, HCD, Prototyping, Contract Negotiations)</td>
                        </tr>

                        <tr>
                          <td colSpan={6} rowSpan={1}>
                          Telco, IT & Data-Driven/AI Services
                          </td>
                          <td colSpan={2} rowSpan={4} className="large_td">
                            <span className="rotate">EDGE Computing </span>
                          </td>
                          <td colSpan={2} rowSpan={7} className="large_td">
                            <span className="rotate">End2End Security</span>
                          </td>
                          <td colSpan={2} rowSpan={7} className="large_td">
                            <span className="rotate">Orchestration Integration</span>
                          </td>
                          <td colSpan={2} rowSpan={7} className="large_td">
                            <span className="rotate">Governance, Discovering Monitoring</span>
                          </td>
                        </tr>
                        <tr>
                          <td colSpan={1} rowSpan={1} className="secondrow">
                            <span className="rotate">Tools & CI</span>
                          </td>
                          <td colSpan={1} rowSpan={1} className="secondrow">
                            <span className="rotate">Service Architecture</span>
                          </td>
                          <td colSpan={1} rowSpan={1} className="secondrow">
                            <span className="rotate">IOT Plattform Services</span>
                          </td>
                          <td colSpan={1} rowSpan={1} className="secondrow">
                            <span className="rotate">Machine Learning (ML)</span>
                          </td>
                          <td colSpan={1} rowSpan={1} className="secondrow">
                            <span className="rotate">Telco Services</span>
                          </td>
                          <td colSpan={1} rowSpan={1} className="secondrow">
                            <span className="rotate">Identity</span>
                          </td>
                        </tr>
                        <tr>
                          <td colSpan={1} rowSpan={1} className="secondrow">
                            <span className="rotate">DBI</span>
                          </td>
                          <td colSpan={1} rowSpan={1} className="secondrow">
                            <span className="rotate">Containers</span>
                          </td>
                          <td colSpan={1} rowSpan={1} className="secondrow">
                            <span className="rotate">IOT Integrastion</span>
                          </td>
                          <td colSpan={1} rowSpan={1} className="secondrow">
                            <span className="rotate">Functions</span>
                          </td>
                          <td colSpan={1} rowSpan={1} className="secondrow">
                            <span className="rotate">Pub/Sub</span>
                          </td>
                          <td colSpan={1} rowSpan={1} className="secondrow">
                            <span className="rotate">Datalake</span>
                          </td>
                        </tr>
                        <tr>
                          <td colSpan={6} rowSpan={1}>
                          Public or Private Cloud
                          </td>
                        </tr>
                        <tr>
                          <td colSpan={8} rowSpan={1}>
                          Network Integration (SDN)
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
                <div className="col-md-5">
                  <p className="first_paragraphe">
                    {" "}
                    X-CITE integrates the most important competencies for
                    digital transformation: Cloud Computing Software define net-
                    works, IOT, Big Data, Information Secu- rity, AI, Robotics
                    and Edge Computing.{" "}
                  </p>
                  <p className="first_paragraphe">
                    {" "}
                    Our range of services goes from consulting, creating
                    architecture for the right solution over creating rapid
                    prototypes to full implementation and operations{" "}
                  </p>
                  <p className="first_paragraphe">
                    {" "}
                    X-CITE is CSP of Alibaba Cloud as only provider in
                    Luxembourg and surroun- dings but offers on top integration
                    with all relevant Hyperscalers like AWS, Google & Azure{" "}
                  </p>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12">
                <h1 className="title_popup">
                  CLOUD ENGINES -MAKING HYBRID CLOUD WORK FOR YOU
                </h1>
              </div>
            </div>
            <div className="popup_container2">
              <div className="col-md-12">
                <h2 className="second_title_popup">
                  {" "}
                  We design, develop, integrate and operate your Cloud
                  infrastructure
                </h2>
              </div>

              <div className="row">
                <div className="col-md-6">
                  <div className="right-side">
                    <h3 className="third-title-popup"> Public Cloud </h3>

                    <ul className="title_list">
                      <li> Sweet Spot </li>
                      <li className="no-bullet">
                        <ul className="list_element">
                          <li>Alibaba (CSP) </li>
                          <li>Google Cloud Platform </li>
                          <li>Amazon Web Services </li>
                        </ul>
                      </li>
                      <li> Continuous Benchmark</li>
                      <li className="no-bullet">
                        <ul className="list_element">
                          <li>Microsoft Azure </li>
                          <li> IBM Cloud </li>
                        </ul>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="left-side">
                    <h3 className="third-title-popup"> Private Cloud </h3>

                    <ul className="title_list">
                      <li> Sweet Spot </li>
                      <li className="no-bullet">
                        <ul className="list_element">
                          <li>Apsara Stack (Alibaba Cloud) </li>
                          <li>vSphere & Cloud Foundry</li>
                          <li>
                            VMware vCloud Suite (Focus on Service Integration){" "}
                          </li>
                          <li>
                            (RedHat) OpenStack, especially for NFV) & OpenShift
                          </li>
                        </ul>
                      </li>
                      <li> Continuous Benchmark</li>
                      <li className="no-bullet">
                        <ul className="list_element">
                          <li>Microsoft Azure Stack </li>
                          <li> OpenStack OSS </li>
                          <li> Ali Apsara Stack</li>
                        </ul>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-md-3">
                  <h3 className="third-title-popup">
                    Value Oriented Planning{" "}
                  </h3>
                  <p className="last_paragraphe">
                    {" "}
                    Cloud Strategy Consultation, Process Consultation and
                    Technology Consulting
                  </p>
                </div>
                <div className="col-md-3">
                  <h3 className="third-title-popup">
                    Step-By-Step Development
                  </h3>
                  <p className="last_paragraphe">
                    Agile Development of Cloud Applications and Infrastructures
                  </p>
                </div>
                <div className="col-md-3">
                  <h3 className="third-title-popup">Seemless Integration </h3>
                  <p className="last_paragraphe">
                    {" "}
                    The complete use of all benefits of the cloud can only be
                    achieved through automation and integration.
                  </p>
                </div>
                <div className="col-md-3">
                  <h3 className="third-title-popup">Automated Operations </h3>
                  <p className="last_paragraphe">
                    {" "}
                    24 hours operation of the Cloud infrastructure with DevOps
                    Methods based on robust ITIL-Processes
                  </p>
                </div>
              </div>
            </div>
          </Panel>
          <Panel
            index="3"
            title="Edge Computing"
            image={edge}
            description=" We design distributed compute system that will play essential part for 5G monetisation cases."
          >
            <div className="close_image">
              <img
                src={close_img}
                alt=""
                onClick={() => this.refs.children.handleChange()}
              />
            </div>

            <div className="popup_container3">
              <div className="row">
                <div className="col-md-6">
                  <h3 className="third-title-popup">
                    {" "}
                    Business Benefits & Approaches{" "}
                  </h3>
                  <ul>
                    <li> Use Cases </li>
                    <li> High Scale IoT Integration </li>
                    <li> Low Latency Data Processing </li>
                    <li>IoT Hub </li>
                  </ul>

                  <h3 className="third-title-popup"> Technologies </h3>
                  <ul>
                    <li> ALIBABA IoT Brain </li>
                    <li> AWS GreenGrass </li>
                    <li> Azure IoT Edge </li>
                  </ul>
                </div>
                <div className="col-md-6">
                  <p>
                    Running AI at the edge faces multiple challenges: First, you
                    need a lot of data, and this is something edge models aren't
                    very good for!
                  </p>
                  <p>
                    Solution: Opt for a more "distributed" approach to compute
                    systems at the edge and use a group of nodes to work on a
                    particular task
                  </p>
                  <p>
                    {" "}
                    X-CITE intends to integrate the most important competencies
                    for digital transformation: Edge Computing will be essential
                    and large part of 5G monetisation cases.
                  </p>
                </div>
              </div>
            </div>
          </Panel>
        </Tab>

        <Tab selected={1} name="children2" ref="children2" onChange={this.onChange.bind(this)}>
          <Panel
            index="4"
            title="AI"
            image={Ai}
            description=" We develop and operate your AI in combination with your IOT and BI solutions."
          >
            <div className="close_image">
              <img
                src={close_img}
                alt=""
                onClick={() => this.refs.children2.handleChange()}
              />
            </div>
            <div className="row">
              <div className="col-md-12">
                <h1 className="title_popup">
                  Bringing AI to enterprise integration
                </h1>
              </div>
            </div>

            <div className="popup_container2">
              <div className="row">
                <div className="col-md-12">
                  <h2 className="second_title_popup">
                    We at X-CITE develops and operates your AI in combination
                    with your IOT and BI solutions
                  </h2>
                </div>
              </div>
              <div className="row">
                <div className="col-md-12">
                  <h3 className="third-title-popup">
                    {" "}
                    Artificial intelligence represents a new way of interfacing
                    with data.{" "}
                  </h3>
                </div>
              </div>
              <div className="row">
                <div className="col-md-6">
                  <div className="left-side">
                    <h3 className="fourth-title-popup">Use Cases </h3>
                    <ul className="title_list">
                      <li> Decision Recommendations </li>
                      <li> Automated Decision Taking </li>
                      <li> Forecasting </li>
                      <li> Find best possible solutions</li>
                    </ul>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="right-side">
                    <h3 className="fourth-title-popup">Frameworks </h3>
                    <ul className="title_list">
                      <li> Tensorflow & Keras </li>
                      <li> Pytorch</li>
                    </ul>
                  </div>
                </div>

                <div className="col-md-6">
                  <div className="left-side">
                    <h3 className="fourth-title-popup">
                      Link to Data Science & BI{" "}
                    </h3>
                    <ul className="title_list">
                      <li>
                        {" "}
                        Integration of Datalakes via Streams and asynchronous
                        ETL{" "}
                      </li>
                      <li> BI as starting point to ML & AI </li>
                      <li> Representation/UI Engines </li>
                    </ul>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="right-side">
                    <h3 className="fourth-title-popup">
                      Enterprise Architecture{" "}
                    </h3>
                    <ul className="title_list">
                      <li>
                        {" "}
                        Established event driven Enterprise Architecture
                        mandatory to introduce Data Driven Business incl. AI
                        taken decisions{" "}
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </Panel>
          <Panel
            index="5"
            title="IOT"
            image={iot}
            description="We develop and operate your M2M and IOT solutions from idea to market."
          >
            <div className="close_image">
              <img
                src={close_img}
                alt=""
                onClick={() => this.refs.children2.handleChange()}
              />
            </div>
            <div className="row">
              <div className="col-md-12">
                <h1 className="title_popup">
                  Bringing AI to enterprise integration
                </h1>
              </div>
            </div>
            <div className="popup_container2">
              <div className="row">
                <div className="col-md-12">
                  <h2 className="second_title_popup">
                    OUR VISION: rapidIOT overall package – From turning an idea
                    into a market-ready product
                  </h2>
                </div>
              </div>

              <div className="row">
                <div className="col-md-12">
                  <p>
                    We integrate our technology focussed on NB-IOT, but also the
                    technology of our partner e.g. Microtronics (or other third
                    party providers) into the products.
                  </p>
                  <p>
                    {" "}
                    X-CITE concentrates on hybrid and particularly public cloud
                    for our cross industry “IOT enabling platform “making sure
                    that we can always offer the newest feature set (AI,
                    robotics) and are perfectly scalable with the highest
                    possible security and redundancy.{" "}
                  </p>
                  <p>
                    {" "}
                    X-CITE concentrates on NB-IOT and 5G, but will always adapt
                    the best radio technology fitting the need of the customer.{" "}
                  </p>
                </div>
              </div>
              <h3 className="third-title-popup">
                {" "}
                OUR METHODOLOGY (to be put in place with first 3 POC’s)
              </h3>

              <ol className="steps">
                <li> CA & ULA</li>
                <li> Change Password</li>
                <li> Secuiry Questions</li>
                <li>Exchange Agreements</li>
              </ol>
            </div>
          </Panel>
          <Panel
            index="6"
            title="Security"
            image={security}
            description=" We aim to provide the best possible security for your environment with focus on cloud technology."
             parentmethod={this.globalchange}
          >
            <div className="close_image">
              <img
                src={close_img}
                alt=""
                onClick={() => this.refs.children2.handleChange()}
              />
            </div>

            <div className="popup_container2">
              <div className="row">
                <div className="col-md-5">
                  <h3 className="third-title-popup">Secure operations</h3>
                  <p>
                    {" "}
                    Security is not a component, but some- thing that must
                    permeate all parts around an IT service. We ensure that
                    security is taken into consideration in every step of the
                    service delivery as key to our customers.{" "}
                  </p>
                  <p>
                    {" "}
                    A key part to ensuring security delivery of our services is
                    our Security Incident Report tool and Security Operations
                    team.{" "}
                  </p>
                </div>
                <div className="col-md-7">
                  <h3 className="fourth-title-popup">Security Services </h3>
                  <p className="firstparagraphe">
                    Security Services Some of the security services that we
                    offer:{" "}
                  </p>

                  <ul>
                    <li>
                      {" "}
                      Access control (VPN, multi-factor authentication, hardware
                      security module){" "}
                    </li>
                    <li>
                      {" "}
                      Advanced physical security (Hardware Security Module){" "}
                    </li>
                    <li> Endpoint Security </li>
                    <li>
                      {" "}
                      Disaster recovery and business continuity (storage and
                      backup services, disaster recovery service){" "}
                    </li>
                    <li>
                      {" "}
                      Encryption and signing services (Hardware Security Module){" "}
                    </li>
                    <li> Firewalls </li>
                    <li> Managed Web Application Firewall </li>
                    <li> Log management </li>
                    <li>
                      {" "}
                      Network traffic monitoring (Network based Intrusion
                      Detection System){" "}
                    </li>
                    <li>
                      {" "}
                      File system monitoring (Host based Intrusion Detection
                      System){" "}
                    </li>
                    <li>
                      {" "}
                      Security monitoring and Log Analysis (security information
                      and event management){" "}
                    </li>
                    <li>
                      {" "}
                      Secure storage services (local storage of critical
                      business data){" "}
                    </li>
                    <li>
                      {" "}
                      Services for secure credit card and personal information
                      (such as PCI DSS 3.0){" "}
                    </li>
                    <li>
                      {" "}
                      Hardware and Software based Multi Factor Authentication{" "}
                    </li>
                    <li>
                      {" "}
                      Vulnerability assessment (our security department checks
                      the state of your environment){" "}
                    </li>
                  </ul>
                </div>
              </div>

              <div className="row">
                <div className="col-md-12">
                  <h3 className="third-title-popup">Service Architectures</h3>
                </div>
              </div>

              <div className="row">
                <div className="col-md-4">
                  <div className="left-side">
                    <h3 className="fourth-title-popup">Business Benefits & Approaches</h3>
                
                    <ul>
                      <li>  Rapid Prototyping & User Acceptance Tests leading agile requirements engineering</li>
                      <li> Proven development practices for parallel development & integration, leading to fast time to market</li>
                      <li> Continuous improvement & release processes</li>
                      </ul>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="center-side">
                    <h3 className="fourth-title-popup">Programming Languages & Frameworks</h3>
                    <ul>
                      <li> Go (Kit, GORM, …)  </li>
                      <li> Python (Flask & especially link to AI!) </li>
                      <li> Node.JS (Loopback) </li>
                      <li> Java (Spring) </li>
                     
                      </ul>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="right-side">
                    <h3 className="fourth-title-popup">Strong focus on Governance & Practices</h3>
                    <ul>
                    <li>Identity Access Management (OpenID & JWT incl. ABAC, RBAC or ACL)   </li>
                      <li>End 2 End Tracing&Logging, Performance Management & Service Discovery </li>
                      <li> Data Reference Management (i.e. to cover GDPR)  </li>
                      <li> Integration Pipelines incl. Blue & Red Deployments </li>
                      <li> API/RPC Versioning & deprecation Policies </li>
                      </ul>
                  </div>
                </div>
              </div>
            </div>
          </Panel>
        </Tab>


        <Tab selected={1} name="children3" ref="children3" onChange={this.onChange.bind(this)}>
          <Panel
            index="7"
            title="Classic IT"
            image={security}
            description="We are actively assisting you to capture in an early stage 5G business opportunities."
            parentmethod={this.globalchange}
          >
            <div className="close_image">
              <img
                src={close_img}
                alt=""
                onClick={() => (this.refs.children3.handleChange())}
              />
            </div>
            <div className="row">
              <div className="col-md-12">
                <h1 className="title_popup">
                  Telecom Networks are part of our core DNA
                </h1>
              </div>
            </div>

            <div className="popup_container">
              <div className="row">
                <div className="col-md-12">
                  <ul>
                    <li> NFV & Containerization</li>
                    <li>5G </li>
                    <li> 4.5G </li>
                    <li>Sliced Core and IMS </li>
                    <li>Broadband (BNG) & Backhaul </li>
                    <li>Managed Enterprise Networks </li>
                    <li>(Software Defined) Network: SDN </li>
                    <li>Private DC </li>
                    <li>WAN </li>
                    <li>Network Security </li>
                  </ul>
                </div>
              </div>
            </div>
          </Panel>
          {/* <Panel
            index="7"
            title="Classic IT"
            image={security}
            description="We are actively assisting you to capture in an early stage 5G business opportunities."
          >
          </Panel> */}
          </Tab>
      </div>
    );
  }

  onChange(field) {
   
    if (field === "children3") {
      this.refs.children2.handleChange();
     this.refs.children.handleChange();
     }
  
     else if (field === "children2") {
      this.refs.children3.handleChange();
     this.refs.children.handleChange();
     }
  
     else {
  
      this.refs.children3.handleChange();
      this.refs.children2.handleChange();
     }
  
 }
 
}

export default Alltabs;
