import React from "react";
import { StaticQuery, graphql } from 'gatsby' 


const Banner = props => (




  <section id="bannermission" className="major">
    <div className="inner">
      <header className="major">
        <h1>
        OUr Mission
        </h1>
        <hr></hr>
      </header>
      <div className="content">
        <p>
      
          We enable connected economies through our
          <strong> open community model.</strong>
        </p>
      </div>
      <div className="blocs_banner">
   
      <div className="row">
          <div className="col-md-6 bloc_messions">
            <div className="blocnumber">
              <div className="number">1 </div>
            </div>
            <div className="text_bloc_mission">We fundamentally change the way businesses and industries operate, creating measurable value for our clients, end users and society. </div>
          </div>

            <div className="col-md-6 bloc_messions">
            <div className="blocnumber">
              <div className="number">2 </div>
            </div>
            <div className="text_bloc_mission">We deliver complex systems by applying real-time delivery methodology to optimise today’s cloud and AI technologies. </div>
          </div>

        
        </div>


 
        <div className="row">
          <div className="col-md-6 bloc_messions">
            <div className="blocnumber">
              <div className="number">3 </div>
            </div>
            <div className="text_bloc_mission">We assist and consult entities in various industries in defining, developing and delivering technical solutions by using new technology like AI, ML, Cognitive, Cloud, SDN </div>
          </div>

            <div className="col-md-6 bloc_messions">
            <div className="blocnumber">
              <div className="number">4 </div>
            </div>
            <div className="text_bloc_mission">We are cloud-born and work with our customers and their partners in transforming their IT landscapes and accelerating their journey to the cloud or the IOT world </div>
          </div>

        
        </div>
      </div>
    </div>
  </section>

);

export default Banner;
