import React from "react";

import microtronics from "../assets/images/logo_micro.png";
import googlecloud from "../assets/images/logo_google_cloud.png";
import amazonwebservices from "../assets/images/logo_amazon.png";
import scrive from "../assets/images/logo_scrive.png";
import azure from "../assets/images/logo_microsoft.png";
import alibabacloud from "../assets/images/logo_alibabacloud.png";


import Tb  from "../components/tab/tb";
import Bannermission from "./Bannermission";


const Banner = props => (
  <section id="banner_what_end_to_end" className="major">
    <div className="inner">
      <div className="blocs_banner_end2end">
        <h1 className="end2end_firsttitle">End2End Digitalization Delivery</h1>
        <div className="row">
          <div className="col-md-6">
            <h2 className="end2end_secondtitle">
              Embracing technology to improve productivity, experience and
              performance{" "}
            </h2>
          </div>
        </div>
        <div className="row">
          <div className="col-md-6">
            <div className="left-side">
              <p>
                {" "}
                All technology companies are continuing their invest- ment in
                CLOUD and IoT techno- logies. They all need partners and
                alliances to expand in these verticalized markets.  {" "}
              </p>
              <p> Intelligent
                infrastructure such as smart cities, autono- mous traffic, smart
                farming, smart retail, smart banking and food security are
                adding significant value across industries. </p>

                <p>As X-CITE we intend
                to be at the forth-front of these developments by building a
                fast growing partner and eco-system for Cloud, IoT and 5G. </p>
            </div>
          </div>
          <div className="col-md-6">
            <div className="right-side">
              <h3>
                {" "}
                Enabling Digitalisation of Companies, cities, government using:{" "}
              </h3>
              <ul>
                <li> Hybrid Cloud Architecture</li>
                <li>IOT & ET Brains </li>
                <li>
                  Artificial Intelligence, Robotic Process Automation, BPMN{" "}
                </li>
                <li>Covering OSS & BSS related topics (Advanced eTOM) </li>
                <li>State of the art Enterprise/Service Architectures </li>
                <li>Integration of </li>
                <li className="no-bullet">
                  <ul>
                    <li>Web </li>
                    <li>Apps </li>
                    <li>Digital Assistants (Alexa, Google Assistant) </li>
                    <li>e-Signing </li>
                  </ul>
                </li>
                <li>Reliable & Secure Infrastructure </li>
              </ul>
            </div>
          </div>
        </div>
        {/* <div className="row picture_bloc">
          <div className="col-md-4 text-center">
            <img src={microtronics} alt="" />
          </div>
          <div className="col-md-4 text-center">
            <img src={googlecloud} alt="" />
          </div>
          <div className="col-md-4 text-center">
            <img src={amazonwebservices} alt="" />
          </div>
        </div>
        <div className="row">
          <div className="col-md-4 text-center">
            <img src={scrive} alt="" />
          </div>
          <div className="col-md-4 text-center">
            <img src={azure} alt="" />
          </div>
          <div className="col-md-4 text-center">
            <img src={alibabacloud} alt="" />
          </div>
        </div> */}

        <div id="Tabs_bloc">

                      <Tb />

        </div>

      </div>


   


    </div>

  

 

  </section>

);

export default Banner;
